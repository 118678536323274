import React, { useEffect, useState } from "react";
import Select from "react-select";

import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import useAxiosFile from "../actions/useaxios";
import Table from "../table/table";
import config from '../actions/config'
import { datetimeFormat } from "../../lib/common";
const NotificationHistory = () => {
  const axiosFile = useAxiosFile();

  const [page, Setpages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [AllList, SetAllList] = useState([]);
const [load, setload] = useState(false);
  
  useEffect(() => {
    GetAllActivity();
  }, [limit, page]);

  const column = [
    { key: '', label: 'SNo', html: (cell, row, rowind, colind) => <div>{rowind + 1}</div> },
    { key: 'Image', label: 'Notification Image', html:  (cell, row, rowind, colind) => <img 
    alt={row?.Image}
    src={
      row?.coupons
      ? `${config.ImG}/coupon/${row?.coupons}/${row?.Image}`
      : `${config.ImG}/pushnotification/${row?._id}/${row?.Image}`
  }/> },
    { key: 'message', label: 'Notification', html: '' },
    { key: 'Whom', label: 'Who', html:  (cell, row, rowind, colind) =><>{row.individual?row.individual:cell}</> },
    { key: 'createdAt', label: 'Time',html: (cell, row, rowind, colind) =><>{datetimeFormat(cell)}</> }
     ]

  const GetAllActivity = async () => {
    var senddata={
      
      limit:limit,
      page:page,
      from:'list'

    }
    

    const { data } = await axiosFile.pushnotification(senddata);
    
    if (data) SetAllList(data);
setload(true)

  };
  const Pagination = (limit, page) => {
setload(false)
    
    Setpages(page);
    setLimit(limit);
  };
  
  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Nofitication History
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/push-notifications"
             
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
     
      <div className="mt-5 notification_table_div">
        <Table
load={load}

          column={column}
          data={AllList}
          Pagination={Pagination}
          excel={true}
          csv={true}
          print={true}
          search={true}
          fileName={"Notification"}
        />
      </div>
    </div>
  );
};

export default NotificationHistory;
