import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import useAxiosFile from "../actions/useaxios";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../table/table";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const options = [
  { value: "All", label: "All" },
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];


const DiscountsAndOffers = () => {
  const axiosFile = useAxiosFile();
  const [discountoffers, setDiscountoffers] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [all, setAll] = useState("All");
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(5);
  
 const history = useHistory()

  useEffect(() => {
    
    // if (pages && limit) {
      discountandoffers();
    // }
  }, [pages, limit,all]);
  const [load, setload] = useState(false);

  const discountandoffers = async (data) => {
    // if (data == "pages") {
        var sendata = {
          action: "status", status: all, page: pages, limit: limit
        }
        var res = await axiosFile.addcoupondata(sendata);
        console.log("resresresresv",res)
        setDiscountoffers(res?.data?.data);
       setload(true)

  };

  const deletecoupon = async () => {
    try {
      let senddata = {
        _id: deleteId,
        action: "delete"
      }

      var data = await axiosFile.deletecoupondata(senddata);
      document.getElementById("closeexampleModalCenter").click();
      discountandoffers();

      if (data?.success == "success") {
        toast.success(data.msg)
      }
      else{
        toast.error(data.msg)
      }
    } catch (e) {
      
    }

  }
  const Applycoupon = async (_id,CouponApplicableFor) => {
    try {
      let senddata = {
        _id: _id,
        action: "Apply",
        CouponApplicableFor : CouponApplicableFor == "all" ? "none" :"all"
      }

      var data = await axiosFile.deletecoupondata(senddata);
      discountandoffers();

      if (data?.success == "success") {
        toast.success(data.msg)
      }
      else{
        toast.error(data.msg)
      }
    } catch (e) {
      
    }

  }
  const resetbutton =  () => {
    setDiscountoffers([])
    setAll("")
    setpages(1)
    setLimit(5)
  }


  const column = [
    {
      key: "",
      label: "S No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    { key: "CouponTitle", label: "Coupon Title", html: "" },
    { key: "CouponCode", label: "CouponCode", html: "" },
    { key: "CouponType", label: "Coupon Type", html: "" },
   
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <NavLink
            className="active_btn"
            // to="/add_discount_offers"
            to={{
              pathname: `/add_discount_offers`,
              state: { data: row, status: "edit" },
            }}
          >
            Edit
          </NavLink>
        </div>
      ),
    },
    {
      key: "_id",
      label: "Delete",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button
            className="active_btn delte_btn mr-3"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            onClick={() => {
              setDeleteId(row?._id);
            }}
          >
            Delete
          </button>
        </div>
      ),
    },
    {
      key: "CouponApplicableFor",
      label: "Apply All",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button
            className="active_btn delte_btn mr-3"
           onClick={()=>Applycoupon(row?._id,row?.CouponApplicableFor)}
          >
            {cell == "all" ? "Applied For All" : "UnApplied For All"}
          </button>
        </div>
      ),
    },
    {
      key: "_id",
      label: "Send",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button
            className="active_btn delte_btn mr-3"
           
            onClick={() => {
              history.push({pathname:'/push-notifications',state:row})
            }}
          >
            Send
          </button>
        </div>
      ),
    },
  ];

  const Pagination = (limit, page) => {
    
    setpages(page);
    setLimit(limit);
  };
  return (
    <>
      <div style={{ color: "black" }}>
        <div className="row">
          <div className="col-12">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Discounts and Offers
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card card_bg">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-xl-7">
                    <div className="d-flex flex-column flex-sm-row align-items-center">
                      <div className="d-flex align-items-center  ">
                        <p className="mb-0 mr-3">Filter</p>
                        <div className=" w-100  mr-3">
                          <Select
                            options={options}
                            value={{label:all}}
                            onChange={(e) => setAll(e.value)}
                            placeholder="3"
                            className="show_select"
                          />
                        </div>
                      </div>

                      {/* <div className="d-flex align-items-center mt-3 mt-sm-0">
                        <button className="convert_btn mr-3">CSV</button>
                        <button className="convert_btn mr-3">Excel</button>
                        <button className="convert_btn mr-3">Print</button>

                      </div> */}
                      <div className="d-flex align-items-center mt-3 mt-sm-0">
                        <button
                          className="convert_btn reset_btn"
                          onClick={() => resetbutton()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-5  mt-3 mt-xl-0">
                    {/* <div className="row"> */}
                      {/* <div className=" col-sm-7 padding_right">
                        <div className="search_input d-flex align-items-center w-100">
                          <FiSearch className="search_icon" />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here"
                          />
                        </div>
                      </div> */}
                      {/* <div className=" col-sm-5 mt-3 mt-sm-0"> */}
                        <NavLink
                          className="d-flex align-items-center justify-content-between active_btn px-3 py-2 w-100 button_right_max_width"
                          to="/add_discount_offers"
                        >
                          <p className="mb-0" style={{ whiteSpace: "nowrap" }}>
                            Add Discount
                          </p>
                          <AiOutlinePlus />
                        </NavLink>
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12 grid-margin discountadd_table_div">
                    <div className="card ride_card bor_none">
                      <div className="card-body table_card_body">
                        <div className="">
                          <Table
                          load={load}
                            column={column}
                            data={discountoffers}
                            Pagination={Pagination}
                            excel={true}
                            csv={true}
                            print={true}
                            search={true}
                            fileName={"DiscountsAndOffers"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal content */}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Confirm Delete
              </h5>
              <button
                type="button"
                id="closeexampleModalCenter"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Are you sure delete this discount details?</p>
              <div className="del_div mt-3 d-flex align-items-center justify-content-end">
                <button
                  className="del_btn mr-3 active_btn "
                  onClick={() => deletecoupon()}
                >
                  Delete
                </button>
                <NavLink
                  to="/dashboard"
                  className="can_btn active_btn"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountsAndOffers;
