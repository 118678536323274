import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Table from "../table/table";
import { datetimeFormat, isEmpty } from "../../lib/common";
import useAxiosFile from "../actions/useaxios";
import { useLocation } from "react-router-dom/cjs/react-router-dom";



const column = [
  {
    key: "S.No",
    label: "S.No",
    html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
  },

  {
    key: "userid", label: "User Name", html: ""
  },
  // { key: "bookingnumber", label: "Date", html: "" },
  // { key: "Address", label: "Address", html: "" },
  { key: "Trip Date", label: "Trip Date",html: (cell, row, rowind, colind) => <div>


    {

    datetimeFormat(row.createdAt)

  }</div>
},
  {
    key: "driverid", label: "Driver",
    html: ""
  },

  {
    key: "bookingnumber", label: "Ride ID", html:""
  },
  { key: "TotalPrice", label: "Fare",  html: ""},
  // {
  //   key: "type", label: "Type",
  //   html: (cell, row, rowind, colind) => <div>{
  //     row?.vehicletype?.vehicletype
  //   }</div>
  // },

//   { key: "View Invoice", label: "View Invoice",  html: (cell, row, rowind, colind) => <div>

//     <NavLink
//   className="active_btn green_btn"
//   to={{pathname:"/rider-invoice",state: {userid:row?.userid[0]?._id}}}

//   >
// Invoice
//   </NavLink>

//   </div>
// }





];


const DriversOrRideHistory = () => {

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tripRoute, setTrips] = useState([]);

  const axios = useAxiosFile();


var location=useLocation()
const driverid=location?.state?.driverid
const [load, setload] = useState(false);


  useEffect(() => {
    getDriverrides();
  }, [limit,pages]);

  const getDriverrides = async (data) => {
    let senddata = {
      _id:driverid,
      status: "endtrip",
      from: "driver",
      page:1,
      limit: limit,
      page: pages,
    };
    //
    var resp = await axios.Trips(data ? data : senddata);

    setTrips(resp?.data);
setload(true)

  };



  const Pagination = (limit, page) => {

    setpages(page);
    setLimit(limit);
  };

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Drivers/Status
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
            
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
          <div className="row mt-md-3">
          <div className="col-12 grid-margin">
            <div className="card ride_card bor_none">
              <div className="card-body table_card_body">
                <div className="triproute_table_width">
                  <Table
load={load}

                    column={column}
                    data={tripRoute}
                    Pagination={Pagination}
                    excel={true}
                    csv={false}
                    print={true}
                    search={true}
                    fileName={"Riders"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default DriversOrRideHistory;
