import React, { useEffect, useMemo, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import Table from "../table/table";
import { allstate } from "../../map/india";
import useAxiosFile from "../actions/useaxios";
import { EncryptData, LoadToast, isEmpty, updateToast } from "../../lib/common";
import ErrorMsg from "../basic-ui/ErrorMsg";
import { useSelector } from "react-redux";
import Delete from "../delete/delete";
import Autocomplete from "react-google-autocomplete";
const AllState = allstate.states.map((it) => {
  return { label: it.name, value: it.code, id: "state" };
});


const optionsThree = [
  { value: "Yes", label: "Restrict", id: "restrict" },
  { value: "No", label: "Not", id: "restrict" },
];

const Mcd = () => {
  /** lp */
  const { pathname, state } = useLocation();
  const { admindetails } = useSelector((state) => state.Youcabdata);

  const history = useHistory();
  const axiosFile = useAxiosFile();
  const [buttontxt, setbutton] = useState("Start");
  const [show, setshow] = useState(false);
  const [pages, Setpages] = useState(1);
  const [Limit, setLimit] = useState(0);
  const [Error, setError] = useState({});
  const [pathnames, setpathname] = useState(pathname.split("/")[1]);
  const [load, setload] = useState(false);

  const [form, setform] = useState({
  state:'',mcd:''
  });

  

  const [List, SetList] = useState([]);
  useEffect(() => {
    if (admindetails?._id)
      setform({ ...form, ...{ ["adminid"]: admindetails?._id } });
  }, [admindetails?._id]);

  useEffect(() => {
    setpathname(pathname.split("/")[1]);
  }, [pathname, state, pages, Limit]);

  useEffect(() => {
    
    if(pathname=='mcd-edit') setform({...form,...state?.state})
  }, [pathname, state]);


  useEffect(() => {
    if (pathnames == "mcd") {
      getList();
    }
  }, [pathnames, pages]);



  const getList = async (_id) => {
    var { success, data, msg } = await axiosFile.mcd({
      action: "mcd",
      _id: _id,
      page: pages,
      limit: Limit
    })

    setform({ ...form, ...data?.[0], ...{ cityfrom: !isEmpty(data?.[0]?.cityfrom) && data?.[0]?.cityfrom, cityto: !isEmpty(data?.[0]?.cityto) && data?.[0]?.cityto } })
    if (!isEmpty(data)) SetList(data)
    else SetList([])
setload(true)

  }







  const ONCHANGE = (e) => {
    
      const { label, value, id,checked } = e?.target ;
      setform({
        ...form,
        ...{ [id]: value },
      });
    };

  const Validation = () => {
    let error = {};
   if(isEmpty(form?.state)) error.state = " state is Required"
   if(isEmpty(form?.mcd)) error.mcd = " mcd is Required"
    //
    if (!isEmpty(error)) {
      setbutton("Try Again");
      setError(error);
      return true;
    } else return false;
  };

  const updateval = async () => {
    setbutton("Processing");
    const id = LoadToast();

    if (!show && Validation()) return updateToast(id, "fix");
    var { success, error, data, msg } = await axiosFile.mcd({
      action: show ? "delete" : pathnames,
      ...form
    });

    updateToast(id, msg, success);
    setbutton("Start");
    if (!isEmpty(success)) {
      if (show) {
        setshow(!show);
        getList();
      } else {
        setbutton("Done");

        setError({})
        history.push("/mcd");
        setform({ ...form, ...{ state: "" } })
      }
    } else {
      setbutton("Try Again");
      setError(error);
    }
  };


  const Edit = (_id) => {
    setbutton("Start");
    history.push(`/mcd-edit`, { state: _id });
  };
  const Deletes = (_id) => {
    setshow(true);
    setform({ ...form, ...{ ["_id"]: _id } });
  };

  const column = [
    {
      key: "",
      label: "SNo",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    {
      key: "state",
      label: "State ",
      html: (cell, row, rowind, colind) => <div>{cell}</div>,
    },
   
    {
      key: "mcd",
      label: "MCD Price",
      html: (cell, row, rowind, colind) => (
        <>

          {/* {!isEmpty(cell) ? cell?.map((it) => <li>{it}</li>) : ""} */}
          {cell}

        </>
      ),
    },
    // { key: "mcd", label: "MCD",   html: (cell, row, rowind, colind) => <div>{cell ? "Applied" : "Not Applied"}</div>, },
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button class="active_btn green_btn" onClick={() => Edit(row)}>
            {" "}
            Edit{" "}
          </button>{" "}
        </div>
      ),
    },
    {
      key: "_id",
      label: "Delete",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button class="active_btn delte_btn mr-3 p-20" onClick={() => Deletes(cell)}>
            {" "}
            Delete{" "}
          </button>{" "}
        </div>
      ),
    },
  ];

  const Pagination = (limit, page) => {

    Setpages(page);
    setLimit(limit);
  };

  /** lp */

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed ">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
              MCD Management{" "}
            </h4>
          </div>
          <div className="col-3 col-sm-2 d-flex justify-content-end ">
            <div
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => {
                if (pathnames=="mcd") {
                  history.push("/mcd-add")
                  setform({})
                  setbutton('Start')
                }
                else {
                  setbutton('Start')
                  history.push("/mcd")

                }
              }}>

              {pathnames=="mcd" ? "Add" : "Back"}
            </div>
          </div>
        </div>
      </div>

      <>
        {pathnames=="mcd" ? (
          <div className="pt-5">
            <Table
load={load}

              column={column}
              data={List}
              Pagination={Pagination}
              excel={true}
              csv={true}
              print={true}
              search={true}
              fileName={pathnames}
            />
          </div>
        ) : (
          <div className="row row_pad_top">
            <div className="col-md-9 grid-margin stretch-card">
              <div className="card form-card">
                <div className=" pt-5">
                  <form className="forms-sample">
                    <Form.Group>
                      <label
                        htmlFor="exampleInputUsername1"
                        className="label-color"
                      >
                        State Title
                      </label>
                      <Form.Control
                        type="text"
                        id="state"
                        placeholder="State zone"
                        className="new_input"
                        value={form?.state}
                        onChange={(e) => ONCHANGE(e)}
                      />
                      <ErrorMsg msg={Error?.state} />


                    </Form.Group>

                    <Form.Group>
                      <label
                        htmlFor="exampleInputUsername1"
                        className="label-color"
                      >
                        MCD Price
                      </label>
                      <Form.Control
                        type="text"
                        id="mcd"
                        placeholder="Enter Postal Code of City"
                        className="new_input"
                        value={form?.mcd}
                        onChange={(e) => ONCHANGE(e)}
                      />
                    <ErrorMsg msg={Error?.mcd} />


                    </Form.Group>
         


                    <div className="mt-5">
                      <button
                        type="button"
                        className=" convert_btn reset_btn mr-4"
                        disabled={!["Start", "Try Again"]?.includes(buttontxt)}
                        onClick={() =>
                          ["Start", "Try Again"]?.includes(buttontxt)
                            ? updateval()
                            : undefined
                        }
                      >
                        {buttontxt}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
        {show && (
          <Delete
            show={show}
            _id={form?._id}
            setshow={setshow}
            Deletes={updateval}
          />
        )}{" "}
      </>
    </div>
  );
};

export default Mcd;
