import React, { useEffect, useState } from "react";
import Select from "react-select";

import { SlCalender } from "react-icons/sl";
import { AiFillEye, AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown, BiChevronRight } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import DatePicker from "react-datepicker";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import totalRides from "../../assets/svg/totalrides.svg";
import dsCancel from "../../assets/svg/ds_cancel.svg";
import completeRides from "../../assets/svg/completerides.svg";
import totalRev from "../../assets/svg/totalrevenue.svg";
import { NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useAxiosFile from "../actions/useaxios";
import { Getaddressdetail, datetimeFormat, isEmpty } from "../../lib/common";
import Table from "../table/table";








const column = [
  {
    key: "rideId",
    label: "S.No",
    html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
  },

  {
    key: "bookingnumber", label: "Ride Id", html: ""
  },

  { key: "Pickup Address", label: "Pickup Address",html: (cell, row, rowind, colind) => <div>

    {
        row.location.pickup
//  Getaddressdetail(row?.pickup[0],row?.pickup[1])
// "dsdfdf"
  }</div>
},
  {
    key: "Drop Address", label: "Drop Address",
    html: (cell, row, rowind, colind) => <div>{
      row.location.dropoff
      //  Getaddressdetail(row?.drop[0],row?.drop[1])
      // "dfvdf"
    }</div>
  },
  {
    key: "Date", label: "Date",
    html: (cell, row, rowind, colind) => <div>
      
      {
      
      datetimeFormat(row?.updatedAt)
    }</div>
  },

  { key: "price", label: "Earned",  html: (cell, row, rowind, colind) => <div>{
    !isEmpty(row.price)?row?.TotalPrice?.grandtotal:0
  }</div>
},
  // {
  //   key: "status", label: "Status",
  //   html: (cell, row, rowind, colind) => <div>{
  //     row?.vehicletype?.vehicletype
  //   }</div>
  // },






];
const DriverInvoiceStatement = () => {

  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tripRoute, setTrips] = useState([]);
  const [load, setload] = useState(false);

  const axios = useAxiosFile();

var location=useLocation()
const userid=location?.state?.userid

  useEffect(() => {
    getUserrides();
  }, [limit,pages]);

  const getUserrides = async (data) => {
    let senddata = {
      _id:userid,
      status: "endtrip",
      from: "user",
      page:1,
      limit: limit,
      page: pages,
    };
    //
    var resp = await axios.Trips(data ? data : senddata);

    setTrips(resp?.data);
setload(true)

  };



  const Pagination = (limit, page) => {
    setload(false)

    setpages(page);
    setLimit(limit);
  };



  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings mt-3  mb-4 ">
              Rider / Statements
            </h4>
          </div>
          <div className="col-3 col-sm-2 mt-3 ">
            <NavLink
              className="hoverable_a text-left text-sm-right mb-4 d-flex align-items-center justify-content-end "
              to="/dashboard"
              
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

        <div className="row mt-md-3">
          <div className="col-12 grid-margin">
            <div className="card ride_card bor_none">
              <div className="card-body table_card_body">
                <div className="triproute_table_width">
                  <Table
load={load}

                    column={column}
                    data={tripRoute}
                    Pagination={Pagination}
                    excel={true}
                    csv={true}
                    print={true}
                    search={true}
                    fileName={"Riders"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default DriverInvoiceStatement;
