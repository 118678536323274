import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../table/table";
import useAxiosFile from "../actions/useaxios";
import { useHistory } from "react-router-dom";

import Select from "react-select";

const DriverALlReport = () => {
  const options = [
    { value: "all", label: "All" },
    { value: "admin", label: "Admin Due Clearence" },
    { value: "driver", label: "Driver Due Clearence" },
    { value: "notpaid", label: "Not Paid" },
    // { value: "noitems", label: "No Items" },
  ];
  const history = useHistory();
  const { state } = useLocation();
  console.log("statestatestatestatestatestate", state.state);
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(0);
  const [Count, setcount] = useState({
    day: 0,
    week: 0,
    month: 0,
    year: 0,
  });
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },

    { key: "bookingnumber", label: "Booking Number", html: "" },
    // { key: 'driverid', label: 'Driver Id', html: '' },
    // { key: 'email', label: 'Email ID', html: '' },
    // { key: 'mobile', label: 'Mobile Number', html: '' },

    { key: "paymentmethod", label: "Payment Method", html: "" },
    { key: "GrandTotal", label: "GrandTotal", html: "" },
    { key: "TotalAmount", label: "Driver Payment", html: "" },
    { key: "adminearning", label: "Admin Earning", html: "" },
    { key: "cancel", label: "Cancelled Trip Value", html: "" },
    { key: "paidby", label: "Paid By", html: "" },
    // {
    //   key: "_id", label: "View", html: (cell, row, rowind, colind) => <div onClick={() => (history.push("/driver_statement_trip", { state: row }))}>
    //     View
    //   </div>
    // },
  ];

  const Pagination = (limit, page) => {
    setpages(page);
    setLimit(limit);
  };
  const axiosFile = useAxiosFile();

  const [listdriverdata, setListdriverdata] = useState([]);
  const [load, setload] = useState(false);

  useEffect(() => {
    // if (pages && limit) {
    listDriverData("all");
    // }
  }, [pages, limit]);

  const listDriverData = async (data) => {
    try {
      let senddata = {
        page: pages,
        limit: limit,
        from: data ? data : "all",
        _id: state?.state?._id,
      };
      var res = await axiosFile.DriverReportIndividualTrip(senddata);
      console.log("resresres", res);
      setListdriverdata(res?.data);
      setload(true)
    } catch (e) {
      setload(true)
    }
  };

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
              {/* DriverReport and Transaction Reports */}
              Driver Payment Reports
            </h4>
          </div>
          <div className="col-3 col-sm-2 ">
            <NavLink
              className="text-left text-sm-right  d-flex align-items-center justify-content-end "
              to=
              "/driver_all_report"
              style={{ color: "black", cursor: "pointer" }}
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>

      <div className="row mb-5 mt-5">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="my-3 px-3" style={{ maxWidth: "250px" }}>
              <Select
                options={options}
                placeholder="Paid "
                classNamePrefix="custom_react_select"
                // value={{value:Alllabel,label:Alllabel}}
                onChange={(e) => {
                  console.log(")))))))))))))))))", e);
                  listDriverData(e.value);
                }}
              />
            </div>
            <div className="card-body table_card_body">
              <Table
                column={column}
                data={listdriverdata}
                Pagination={Pagination}
                excel={true}
                csv={true}
                print={true}
                search={true}
                load={load}
                fileName={"Drivers"}
              />
            </div>
          </div>
        </div>
        {/* <div className="col-12">
          
          <div className="card card_bg">
            <div className="card-body">
              <div className="row mt-4">
             
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DriverALlReport;
