import React, { useEffect, useState } from "react";
import {  BiChevronRight } from "react-icons/bi";
import dailyEarnings from "../../assets/svg/dailyEarnings.svg";
import { NavLink } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../table/table";
import useAxiosFile from "../actions/useaxios";
import { Link, useHistory } from "react-router-dom";



const UserReport = () => {
  const history = useHistory()
  const [pages, setpages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [load, setload] = useState(false);
  const [Count, setcount] = useState({
    day:0,
    week:0,
    month:0,
    year:0
  });
  const column = [
    {
      key: "S.No",
      label: "S.No",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
   
    { key: 'fullname', label: 'User Name', html: ''},
    { key: 'userid', label: 'User Id', html: '' },
    { key: 'email', label: 'Email ID', html: ''},
    { key: 'mobile', label: 'Mobile Number', html: ''},
  
    { key: 'TotalAmount', label: 'Complete Trip Value', html: '' },
    { key: 'cancel', label: 'Cancelled Trip Value', html: '' },
//     { key: "_id", label: "View Invoice", 
//      html: (cell, row, rowind, colind) => <div onClick={()=>history.push("/statement",{state:row })}>
//      View
//    </div>
//  },
    
  ];

const Pagination = (limit, page) => {
console.log("***********",limit,page)
setload(false)  
setpages(page);

  setLimit(limit);
  // listDriverData(page);

};
  const axiosFile = useAxiosFile();

  const [listdriverdata, setListdriverdata] = useState([]);
  
  useEffect(() => {
    // if (pages && limit) {
      listDriverData("data");
      console.log("---------------",limit,pages)
    // }
  }, [
    pages,limit
  ])
  
  const listDriverData = async (page) => {
    try {
        let senddata = {
          page: pages, limit: limit
        }
        var res = await axiosFile.UserReport(senddata);
        console.log("res?.data",res,pages,limit)
        setListdriverdata(res?.data);
        setcount({
          day:res.day,
          week:res.week,
          month:res.month,
          year:res.year
        })
        setload(true)
       
    } catch (e) {
  
    }
  
  }
  


  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
              UserReport and Transaction Reports
            </h4>
          </div>
          <div className="col-3 col-sm-2 ">
            <NavLink
              className="text-left text-sm-right  d-flex align-items-center justify-content-end "
              to="/dashboard"
              style={{ color: "black", cursor: "pointer" }}
            >
              Back
            </NavLink>
          </div>
        </div>
      </div>
    
      <div className="row mb-5 mt-5">
        <div className="col-12 grid-margin">
          <div className="card ride_card">
            <div className="card-body table_card_body">
            <Table
                          column={column}
                          load={load}
                          data={listdriverdata}
                          Pagination={Pagination}
                          excel={true}
                          csv={true}
                          print={true}
                          search={true}
                          fileName={"Drivers"}
                        />
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default UserReport;
