import React, { useEffect, useMemo, useState } from "react";
import { Form, Pagination } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import Table from "../table/table";
import { allstate } from "../../map/india";
import useAxiosFile from "../actions/useaxios";
import { EncryptData, LoadToast, isEmpty, updateToast } from "../../lib/common";
import ErrorMsg from "../basic-ui/ErrorMsg";
import { useSelector } from "react-redux";
import Delete from "../delete/delete";
import Autocomplete from "react-google-autocomplete";
import config from '../actions/config'


const AllState = allstate.states.map((it) => {
  return { label: it.name, value: it.code, id: "state" };
});


const optionsThree = [
  { value: "Yes", label: "Restrict", id: "restrict" },
  { value: "No", label: "Not", id: "restrict" },
];

const VehicleVarients = () => {
  /** lp */
  const { pathname, state } = useLocation();
  const { admindetails } = useSelector((state) => state.Youcabdata);

  const history = useHistory();
  const axiosFile = useAxiosFile();
  const [buttontxt, setbutton] = useState("Start");
  const [show, setshow] = useState(false);
  const [pages, Setpages] = useState(1);
  const [Limit, setLimit] = useState(0);
  const [Error, setError] = useState({});
  const [pathnames, setpathname] = useState(pathname);

  const [form, setform] = useState({
   image:'',name:'',_id:""
  });

  const [List, SetList] = useState([]);
  const [load, setload] = useState(false);

  useEffect(() => {
    if (admindetails?._id)
      setform({ ...form, ...{ ["adminid"]: admindetails?._id } });
  }, [admindetails?._id]);

  useEffect(() => {
    setpathname(pathname);
  }, [pathname, state, pages, Limit]);

  useEffect(() => {
    if(pathname?.includes('edit')) setform({...form,...state.state})
  }, [pathname, state]);


  useEffect(() => {
    if (pages&&pathnames == "/vehiclearients") {
      getList();
    }
  }, [pathnames, pages,Limit]);



  const getList = async (_id) => {
    // 
    var { success, data, msg } = await axiosFile.vehiclevarient({
      action: 'all',
      _id: _id,
      page: pages,
      limit: Limit
    })
  
    // setform({ ...form, ...data?.[0], ...{ cityfrom: !isEmpty(data?.[0]?.cityfrom) && data?.[0]?.cityfrom, cityto: !isEmpty(data?.[0]?.cityto) && data?.[0]?.cityto } })
    if (!isEmpty(data)) SetList(data)
    else SetList([])
    setload(true)

  }







  const ONCHANGE = (e) => {
    
      const { label, value, id,checked } = e?.target ;
      setError({})
      setbutton("Start")
      setform({
        ...form,
        ...{ [id]:id == "mcd" ? checked: value },
      });
    };

  const Validation = () => {
    let error = {};
    if(isEmpty(form.name)) error.name ="vehicle varient is reuired"
    if(isEmpty((pathnames.split('/')[1]=="edit"&&form.image)||(pathnames.split('/')[1]=="add"&&form.image?.name))) error.image ="Image varient is reuired"
   
    if (!isEmpty(error)) {

      setbutton("Try Again");
      setError(error);
      return true;
    } else return false;
  };

  const updateval = async (from,datas) => {
    setbutton("Processing");
    const id = LoadToast();

    if (from != "delete" && Validation()) return updateToast(id, "fix");
    var data = await axiosFile.vehiclevarient({
      action: from == "delete" ? "delete" : pathnames.split('/')[1],
      row_id:form?._id,
      ...from == "delete" ?datas:form
    });

    updateToast(id, data?.msg, data?.success);
    setbutton("Start");
    
    if (!isEmpty(data?.success)) {
      if (from == "delete") {
        setshow(!show);
        getList();
        setError({})
        setbutton("Start")
      } else {
        // setbutton("Done");
        // setError({})
        setbutton("Start")
        setError({})
        history.push("/vehiclearients");
        setform({ ...form, ...{ state: "" } })
      }
    } else {
      setbutton("Try Again");
      setError(data?.error);
    }
  };


  const Edit = (_id) => {
    setbutton("Start");
    history.push(`/vehiclearients-edit`, { state: _id });
  };
  const Deletes = (_id) => {
  
    setform(_id);
    setshow(true)
    // updateval('delete',_id)
  };

  const column = [
    {
      key: "",
      label: "SNo",
      html: (cell, row, rowind, colind) => <div>{rowind + 1}</div>,
    },
    {
      key: "name",
      label: "Car Varient ",
      html: (cell, row, rowind, colind) => <div>{cell}</div>,
    },
   
    
    { key: "image", label: "Image",   html: (cell, row, rowind, colind) => <div><img src={`${config.ImG}/vehicle/${row?._id}/${cell}`} alt="car"/></div>, },
    {
      key: "_id",
      label: "Edit",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button class="active_btn green_btn" onClick={() => Edit(row)}>
            {" "}
            Edit{" "}
          </button>{" "}
        </div>
      ),
    },
    {
      key: "_id",
      label: "Delete",
      html: (cell, row, rowind, colind) => (
        <div>
          {" "}
          <button class="active_btn delte_btn mr-3 p-20" onClick={() => Deletes(row)}>
            {" "}
            Delete{" "}
          </button>{" "}
        </div>
      ),
    },
  ];

  const Pagination = (limit, page) => {
    setload(false)

    Setpages(page);
    setLimit(limit);
  };
  async function handleChange(event) {
    var error = {};
    const { id, files } = event.target;

    var file = files[0];
setform({...form,...{image:file}})
    // if (isEmpty(addvehicletype?.file)) {
      // error.file = "file field is required";
    // }
    // setFile((filesimg) => ([...filesimg,...file]))

  }
  /** lp */

  return (
    <div style={{ color: "black" }}>
      <div className="title_fixed ">
        <div className="row align-items-center py-3">
          <div className="col-9 col-sm-10">
            <h4 className="card-title new_headings  mb-0">
             Vehicle Varients {" "}
            </h4>
          </div>
          <div className="col-3 col-sm-2 d-flex justify-content-end ">
            <div
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => {
                if (['/vehiclearients-add','/vehiclearients-edit']?.includes(pathnames) ) {
                  setbutton('Start')
                  history.push("/vehiclearients")

               
                }
                else {
                  history.push("/vehiclearients-add")
                  setform({})
                  setbutton('Start')
                }
              }}>
              {['/vehiclearients-add','/vehiclearients-edit']?.includes(pathnames)? "Back" : "Add"}
            </div>
          </div>
        </div>
      </div>
      <>
        {['/vehiclearients-add','/vehiclearients-edit']?.includes(pathnames) ? (
         <div className="row row_pad_top">
         <div className="col-md-9 grid-margin stretch-card">
           <div className="card form-card">
             <div className=" pt-5">
               <form className="forms-sample">
                 <Form.Group>
                   <label
                     htmlFor="exampleInputUsername1"
                     className="label-color"
                   >
                     vehicle Varient Type
                   </label>
                   <Form.Control
                     type="text"
                     id="name"
                     placeholder="Vehicle varient type"
                     className="new_input"
                     value={form?.name}
                     onChange={(e) => ONCHANGE(e)}
                   />
                   <ErrorMsg msg={Error?.name} />


                 </Form.Group>

                 <Form.Group>
                   <label
                     htmlFor="exampleInputUsername1"
                     className="label-color"
                   >
                     vehicle Varient Type Description
                   </label>
                   <Form.Control
                     type="text"
                     id="desc"
                     placeholder="Vehicle varient type description"
                     className="new_input"
                     value={form?.desc}
                     onChange={(e) => ONCHANGE(e)}
                   />
                   <ErrorMsg msg={Error?.desc} />


                 </Form.Group>

                 
                 
                 <Form.Group>

                 
                 <Form.Control
                 type="file"
                 id="image"
                 placeholder="Car Type"
                 className="new_input new_input_add"
                 onChange={(event) => handleChange(event)}
               />

              <img src={typeof form?.image == "object" ? URL.createObjectURL(form.image):form?.image ? `${config.ImG}/vehicle/${form?._id}/${form?.image}`:"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png"}/>
                   <ErrorMsg msg={Error?.image} />


                 </Form.Group>


                 <div className="mt-5">
                   <button
                     type="button"
                     className=" convert_btn reset_btn mr-4"
                     disabled={!["Start", "Try Again"]?.includes(buttontxt)}
                     onClick={() =>
                       ["Start", "Try Again"]?.includes(buttontxt)
                         ? updateval()
                         : undefined
                     }
                   >
                     {buttontxt}
                   </button>
                 </div>
               </form>
             </div>
           </div>
         </div>
       </div>
       
        ) : (
          <div className="pt-5">
            <Table
              column={column}
              data={List}
              load={load}

              Pagination={Pagination}
              excel={true}
              csv={true}
              print={true}
              search={true}
              fileName={pathnames}
            />
          </div>
        )}
        {show && (
          <Delete
            show={show}
            _id={form?._id}
            setshow={setshow}
            text={'Vehicle Varient'}
            Deletes={()=>updateval('delete',form)}
          />
        )}{" "}
      </>
    </div>
  );
};

export default VehicleVarients;
